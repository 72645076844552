  /* default property reset */
  body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
  form,fieldset,input,textarea,
  th,td {margin:0;padding:0;}
  table {border-collapse:collapse;border-spacing:0;}
  fieldset,img {border:0;}
  ol,ul {list-style:none;}
  caption,th {text-align:left;}
  h1,h2,h3,h4,h5,h6 {font-size:100%;font-weight:normal;}
  q:before,q:after {content:'';}
  abbr,acronym {border:0;}
  p{padding:0;margin:0;}
  img{padding:0;margin:0;vertical-align:middle;}
  
  @import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
  @import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
  
  *{
    -moz-box-sizing: border-box;        /* Firefox */  
    -webkit-box-sizing: border-box;     /* Safari 3 */  
    -ms-box-sizing: border-box;         /* Internet Explorer 8 */  
    box-sizing: border-box;             /* Opera */ 
    padding:0;
  }
  
  @media (min-width: 800px) {
  
  }
  
  @media (max-width: 800px) { 
  
    html,body {
      font-size:13px;
      font-family: 'Noto Sans JP', sans-serif!important;
      font-weight:400;
      margin:0;
      padding:0;
    }
  
    section {
      width:100%;
      position:relative;
    }
  
    .gritmenu {
      width:100%;
      padding:0 15px;
      margin:0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .gritmenu div.btnblock  {
      display:block;
      width:calc(50% - 6px);
      height:24vw;
      margin:3px;
      border-radius: 5px;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -ms-transition: 0.3s;
      transition: 0.3s;
      padding:1px;
    }
  
    .gritmenu div.btnblock.size2 {
      width:calc(100% - 6px)!important;
  
    }
  
    .gritmenu div.btnblock div.wrap {
      display: block;
      height: 100%;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      position:relative;
    }
  
    .gritmenu div.btnblock div.wrap div.svg {
      width:100%;
      height:17vw;
      position:relative;
    }
  
    .gritmenu div.btnblock div.wrap div.svg svg {
      position:absolute;
      z-index:100;
      left:50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      height:11vw;
      width:auto;
    }

    .gritmenu div.advanceorder div.wrap div.svg svg {
      height:12vw;
    }

  
    .gritmenu div.btnblock div.wrap div.icon_name {
      width:100%;
      font-weight:500;
      position:absolute;
      bottom:5px;
      left:0;
      line-height:1.2;
      padding: 0 12px;
    }
  
  
  
  
  
  
  
    /* color001 */
    .color001 div.btnblock  {
      text-shadow: 0px -1px 0px #B71C1C;
      background: #fff;
      border: 1px solid #F44336;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color001 div.btnblock div.wrap {
      background: #EF5350;
      background: -webkit-gradient(linear, left top, left bottom, from(#E57373), to(#F44336));
      background: -moz-linear-gradient(top,  #E57373,  #F44336);
      background: -o-linear-gradient(top,  #E57373,  #F44336);
      background: -ms-linear-gradient(top,  #E57373,  #F44336);
      background: linear-gradient(top,  #E57373,  #F44336);
    }
  
    .color001 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color001 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color002 */
    .color002 div.btnblock  {
      text-shadow: 0px -1px 0px #880E4F;
      background: #fff;
      border: 1px solid #E91E63;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color002 div.btnblock div.wrap {
      background: #EC407A;
      background: -webkit-gradient(linear, left top, left bottom, from(#F06292), to(#E91E63));
      background: -moz-linear-gradient(top,  #F06292,  #E91E63);
      background: -o-linear-gradient(top,  #F06292,  #E91E63);
      background: -ms-linear-gradient(top,  #F06292,  #E91E63);
      background: linear-gradient(top,  #F06292,  #E91E63);
    }
  
    .color002 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color002 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color003 */
    .color003 div.btnblock  {
      text-shadow: 0px -1px 0px #4A148C;
      background: #fff;
      border: 1px solid #BA68C8;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color003 div.btnblock div.wrap {
      background: #AB47BC;
      background: -webkit-gradient(linear, left top, left bottom, from(#9C27B0), to(#BA68C8));
      background: -moz-linear-gradient(top,  #9C27B0,  #BA68C8);
      background: -o-linear-gradient(top,  #9C27B0,  #BA68C8);
      background: -ms-linear-gradient(top,  #9C27B0,  #BA68C8);
      background: linear-gradient(top,  #9C27B0,  #BA68C8);
    }
  
    .color003 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color003 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color004 */
    .color004 div.btnblock  {
      text-shadow: 0px -1px 0px #311B92;
      background: #fff;
      border: 1px solid #673AB7;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color004 div.btnblock div.wrap {
      background: #7E57C2;
      background: -webkit-gradient(linear, left top, left bottom, from(#673AB7), to(#9575CD));
      background: -moz-linear-gradient(top,  #673AB7,  #9575CD);
      background: -o-linear-gradient(top,  #673AB7,  #9575CD);
      background: -ms-linear-gradient(top,  #673AB7,  #9575CD);
      background: linear-gradient(top,  #673AB7,  #9575CD);
    }
  
    .color004 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color004 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color005 */
    .color005 div.btnblock  {
      text-shadow: 0px -1px 0px #1A237E;
      background: #fff;
      border: 1px solid #3F51B5;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color005 div.btnblock div.wrap {
      background: #5C6BC0;
      background: -webkit-gradient(linear, left top, left bottom, from(#3F51B5), to(#7986CB));
      background: -moz-linear-gradient(top,  #3F51B5,  #7986CB);
      background: -o-linear-gradient(top,  #3F51B5,  #7986CB);
      background: -ms-linear-gradient(top,  #3F51B5,  #7986CB);
      background: linear-gradient(top,  #3F51B5,  #7986CB);
    }
  
    .color005 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color005 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color006 */
    .color006 div.btnblock  {
      text-shadow: 0px -1px 0px #0D47A1;
      background: #fff;
      border: 1px solid #2196F3;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color006 div.btnblock div.wrap {
      background: #42A5F5;
      background: -webkit-gradient(linear, left top, left bottom, from(#2196F3), to(#64B5F6));
      background: -moz-linear-gradient(top,  #2196F3,  #64B5F6);
      background: -o-linear-gradient(top,  #2196F3,  #64B5F6);
      background: -ms-linear-gradient(top,  #2196F3,  #64B5F6);
      background: linear-gradient(top,  #2196F3,  #64B5F6);
    }
  
    .color006 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color006 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
      /* color007 */
      .color007 div.btnblock  {
      text-shadow: 0px -1px 0px #01579B;
      background: #fff;
      border: 1px solid #03A9F4;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color007 div.btnblock div.wrap {
      background: #29B6F6;
      background: -webkit-gradient(linear, left top, left bottom, from(#03A9F4), to(#4FC3F7));
      background: -moz-linear-gradient(top,  #03A9F4,  #4FC3F7);
      background: -o-linear-gradient(top,  #03A9F4,  #4FC3F7);
      background: -ms-linear-gradient(top,  #03A9F4,  #4FC3F7);
      background: linear-gradient(top,  #03A9F4,  #4FC3F7);
    }
  
    .color007 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color007 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color008 */
    .color008 div.btnblock  {
      text-shadow: 0px -1px 0px #006064;
      background: #fff;
      border: 1px solid #00BCD4;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color008 div.btnblock div.wrap {
      background: #26C6DA;
      background: -webkit-gradient(linear, left top, left bottom, from(#00BCD4), to(#4DD0E1));
      background: -moz-linear-gradient(top,  #00BCD4,  #4DD0E1);
      background: -o-linear-gradient(top,  #00BCD4,  #4DD0E1);
      background: -ms-linear-gradient(top,  #00BCD4,  #4DD0E1);
      background: linear-gradient(top,  #00BCD4,  #4DD0E1);
    }
  
    .color008 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color008 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color009 */
    .color009 div.btnblock  {
      text-shadow: 0px -1px 0px #004D40;
      background: #fff;
      border: 1px solid #009688;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color009 div.btnblock div.wrap {
      background: #26A69A;
      background: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#4DB6AC));
      background: -moz-linear-gradient(top,  #009688,  #4DB6AC);
      background: -o-linear-gradient(top,  #009688,  #4DB6AC);
      background: -ms-linear-gradient(top,  #009688,  #4DB6AC);
      background: linear-gradient(top,  #009688,  #4DB6AC);
    }
  
    .color009 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color009 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color010 */
    .color010 div.btnblock  {
      text-shadow: 0px -1px 0px #1B5E20;
      background: #fff;
      border: 1px solid #4CAF50;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color010 div.btnblock div.wrap {
      background: #66BB6A;
      background: -webkit-gradient(linear, left top, left bottom, from(#4CAF50), to(#81C784));
      background: -moz-linear-gradient(top,  #4CAF50,  #81C784);
      background: -o-linear-gradient(top,  #4CAF50,  #81C784);
      background: -ms-linear-gradient(top,  #4CAF50,  #81C784);
      background: linear-gradient(top,  #4CAF50,  #81C784);
    }
  
    .color010 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color010 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color011 */
    .color011 div.btnblock  {
      text-shadow: 0px -1px 0px #33691E;
      background: #fff;
      border: 1px solid #8BC34A;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color011 div.btnblock div.wrap {
      background: #9CCC65;
      background: -webkit-gradient(linear, left top, left bottom, from(#8BC34A), to(#AED581));
      background: -moz-linear-gradient(top,  #8BC34A,  #AED581);
      background: -o-linear-gradient(top,  #8BC34A,  #AED581);
      background: -ms-linear-gradient(top,  #8BC34A,  #AED581);
      background: linear-gradient(top,  #8BC34A,  #AED581);
    }
  
    .color011 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color011 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color012 */
    .color012 div.btnblock  {
      background: #fff;
      border: 1px solid #CDDC39;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color012 div.btnblock div.wrap {
      background: #D4E157;
      background: -webkit-gradient(linear, left top, left bottom, from(#CDDC39), to(#DCE775));
      background: -moz-linear-gradient(top,  #CDDC39,  #DCE775);
      background: -o-linear-gradient(top,  #CDDC39,  #DCE775);
      background: -ms-linear-gradient(top,  #CDDC39,  #DCE775);
      background: linear-gradient(top,  #CDDC39,  #DCE775);
    }
  
    .color012 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color012 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color013 */
    .color013 div.btnblock  {
      background: #fff;
      border: 1px solid #FFEB3B;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color013 div.btnblock div.wrap {
      background: #FFEE58;
      background: -webkit-gradient(linear, left top, left bottom, from(#FFEB3B), to(#FFF176));
      background: -moz-linear-gradient(top,  #FFEB3B,  #FFF176);
      background: -o-linear-gradient(top,  #FFEB3B,  #FFF176);
      background: -ms-linear-gradient(top,  #FFEB3B,  #FFF176);
      background: linear-gradient(top,  #FFEB3B,  #FFF176);
    }
  
    .color013 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color013 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color014 */
    .color014 div.btnblock  {
      background: #fff;
      border: 1px solid #FFC107;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color014 div.btnblock div.wrap {
      background: #FFCA28;
      background: -webkit-gradient(linear, left top, left bottom, from(#FFC107), to(#FFD54F));
      background: -moz-linear-gradient(top,  #FFC107,  #FFD54F);
      background: -o-linear-gradient(top,  #FFC107,  #FFD54F);
      background: -ms-linear-gradient(top,  #FFC107,  #FFD54F);
      background: linear-gradient(top,  #FFC107,  #FFD54F);
    }
  
    .color014 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color014 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color015 */
    .color015 div.btnblock  {
      background: #fff;
      border: 1px solid #FF9800;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color015 div.btnblock div.wrap {
      background: #FFA726;
      background: -webkit-gradient(linear, left top, left bottom, from(#FF9800), to(#FFB74D));
      background: -moz-linear-gradient(top,  #FF9800,  #FFB74D);
      background: -o-linear-gradient(top,  #FF9800,  #FFB74D);
      background: -ms-linear-gradient(top,  #FF9800,  #FFB74D);
      background: linear-gradient(top,  #FF9800,  #FFB74D);
    }
  
    .color015 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color015 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color016 */
    .color016 div.btnblock  {
      background: #fff;
      border: 1px solid #FF5722;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color016 div.btnblock div.wrap {
      background: #FF7043;
      background: -webkit-gradient(linear, left top, left bottom, from(#FF5722), to(#FF8A65));
      background: -moz-linear-gradient(top,  #FF5722,  #FF8A65);
      background: -o-linear-gradient(top,  #FF5722,  #FF8A65);
      background: -ms-linear-gradient(top,  #FF5722,  #FF8A65);
      background: linear-gradient(top,  #FF5722,  #FF8A65);
    }
  
    .color016 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color016 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color017 */
    .color017 div.btnblock  {
      text-shadow: 0px -1px 0px #3E2723;
      background: #fff;
      border: 1px solid #795548;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color017 div.btnblock div.wrap {
      background: #8D6E63;
      background: -webkit-gradient(linear, left top, left bottom, from(#795548), to(#A1887F));
      background: -moz-linear-gradient(top,  #795548,  #A1887F);
      background: -o-linear-gradient(top,  #795548,  #A1887F);
      background: -ms-linear-gradient(top,  #795548,  #A1887F);
      background: linear-gradient(top,  #795548,  #A1887F);
    }
  
    .color017 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color017 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* color018 */
    .color018 div.btnblock  {
      background: #fff;
      border: 1px solid #9E9E9E;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color018 div.btnblock div.wrap {
      background: #BDBDBD;
      background: -webkit-gradient(linear, left top, left bottom, from(#9E9E9E), to(#E0E0E0));
      background: -moz-linear-gradient(top,  #9E9E9E,  #E0E0E0);
      background: -o-linear-gradient(top,  #9E9E9E,  #E0E0E0);
      background: -ms-linear-gradient(top,  #9E9E9E,  #E0E0E0);
      background: linear-gradient(top,  #9E9E9E,  #E0E0E0);
    }
  
    .color018 div.btnblock div.wrap div.svg {
      fill:#333;
    }
  
    .color018 div.btnblock div.wrap div.icon_name {
      color:#333;
    }
  
    /* color019 */
    .color019 div.btnblock  {
      text-shadow: 0px -1px 0px #263238;
      background: #fff;
      border: 1px solid #607D8B;
      -webkit-box-shadow: 1px 1px 2px #ccc;
      -moz-box-shadow: 1px 1px 2px #ccc;
      box-shadow: 1px 1px 2px #ccc;
    }
  
    .color019 div.btnblock div.wrap {
      background: #78909C;
      background: -webkit-gradient(linear, left top, left bottom, from(#607D8B), to(#90A4AE));
      background: -moz-linear-gradient(top,  #607D8B,  #90A4AE);
      background: -o-linear-gradient(top,  #607D8B,  #90A4AE);
      background: -ms-linear-gradient(top,  #607D8B,  #90A4AE);
      background: linear-gradient(top,  #607D8B,  #90A4AE);
    }
  
    .color019 div.btnblock div.wrap div.svg {
      fill:#fff;
    }
  
    .color019 div.btnblock div.wrap div.icon_name {
      color:#fff;
    }
  
    /* バナー */
    .banner {
      width:100%;
      padding:20px 18px;
    }
  
    .banner div {
      width:100%;
      margin-bottom:10px;
    }
  
    .banner div img {
      width:100%;
      border-radius:5px;
    }
  
  
  
    /* 汎用 */
  
    h2 {
      position: relative;
      font-size:120%;
      font-weight:700;
      padding-bottom:5px;
      margin-bottom:15px;
    }
  
    h2:after {
      position: absolute;
      content: " ";
      display: block;
      bottom: -3px;
      width: 30%;
    }
  
    .color001 h2 {border-bottom: solid 3px #EF9A9A;}
    .color001 h2:after {border-bottom: solid 3px #D32F2F;}

    .color002 h2 {border-bottom: solid 3px #F48FB1;}
    .color002 h2:after {border-bottom: solid 3px #C2185B;}

    .color003 h2 {border-bottom: solid 3px #CE93D8;}
    .color003 h2:after {border-bottom: solid 3px #7B1FA2;}

    .color004 h2 {border-bottom: solid 3px #B39DDB;}
    .color004 h2:after {border-bottom: solid 3px #512DA8;}

    .color005 h2 {border-bottom: solid 3px #9FA8DA;}
    .color005 h2:after {border-bottom: solid 3px #303F9F;}

    .color006 h2 {border-bottom: solid 3px #90CAF9;}
    .color006 h2:after {border-bottom: solid 3px #1976D2;}

    .color007 h2 {border-bottom: solid 3px #81D4FA;}
    .color007 h2:after {border-bottom: solid 3px #0288D1;}

    .color008 h2 {border-bottom: solid 3px #80DEEA;}
    .color008 h2:after {border-bottom: solid 3px #0097A7;}

    .color009 h2 {border-bottom: solid 3px #80CBC4;}
    .color009 h2:after {border-bottom: solid 3px #00796B;}

    .color010 h2 {border-bottom: solid 3px #A5D6A7;}
    .color010 h2:after {border-bottom: solid 3px #388E3C;}

    .color011 h2 {border-bottom: solid 3px #C5E1A5;}
    .color011 h2:after {border-bottom: solid 3px #689F38;}

    .color012 h2 {border-bottom: solid 3px #E6EE9C;}
    .color012 h2:after {border-bottom: solid 3px #AFB42B;}

    .color013 h2 {border-bottom: solid 3px #FFF59D;}
    .color013 h2:after {border-bottom: solid 3px #FBC02D;}

    .color014 h2 {border-bottom: solid 3px #FFE082;}
    .color014 h2:after {border-bottom: solid 3px #FFA000;}

    .color015 h2 {border-bottom: solid 3px #FFCC80;}
    .color015 h2:after {border-bottom: solid 3px #F57C00;}

    .color016 h2 {border-bottom: solid 3px #FFAB91;}
    .color016 h2:after {border-bottom: solid 3px #E64A19;}

    .color017 h2 {border-bottom: solid 3px #BCAAA4;}
    .color017 h2:after {border-bottom: solid 3px #5D4037;}

    .color018 h2 {border-bottom: solid 3px #EEEEEE;}
    .color018 h2:after {border-bottom: solid 3px #616161;}

    .color019 h2 {border-bottom: solid 3px #B0BEC5;}
    .color019 h2:after {border-bottom: solid 3px #455A64;}



    ul.list li span.title span.tag {
      display:inline-block;
      width:fit-content;
      font-size:80%;
      font-weight:500;
      padding:0 5px;
      margin-right:3px;
      border-radius:3px;
    }

    .color001 ul.list li span.title span.tag {
      color:#fff;
      background:#D32F2F;
    }
  
    .color002 ul.list li span.title span.tag {
      color:#fff;
      background:#C2185B;}
  
    .color003 ul.list li span.title span.tag {
      color:#fff;
      background:#7B1FA2;}
  
    .color004 ul.list li span.title span.tag {
      color:#fff;
      background:#512DA8;}
  
    .color005  ul.list li span.title span.tag {
      color:#fff;
      background:#303F9F;}
  
    .color006  ul.list li span.title span.tag {
      color:#fff;
      background:#1976D2;}
  
    .color007  ul.list li span.title span.tag {
      color:#fff;
      background:#0288D1;}
  
    .color008  ul.list li span.title span.tag {
      color:#fff;
      background:#0097A7;}
  
    .color009  ul.list li span.title span.tag {
      color:#fff;
      background:#00796B;}
  
    .color010  ul.list li span.title span.tag {
      color:#fff;
      background:#388E3C;}
  
    .color011  ul.list li span.title span.tag {
      color:#fff;
      background:#689F38;}
  
    .color012  ul.list li span.title span.tag {
      color:#fff;
      background:#AFB42B;}
  
    .color013  ul.list li span.title span.tag {
      color:#fff;
      background:#FBC02D;}
  
    .color014  ul.list li span.title span.tag {
      color:#fff;
      background:#FFA000;}
  
    .color015  ul.list li span.title span.tag {
      color:#fff;
      background:#F57C00;}
  
    .color016  ul.list li span.title span.tag {
      color:#fff;
      background:#E64A19;}
  
    .color017  ul.list li span.title span.tag {
      color:#fff;
      background:#5D4037;}
  
    .color018  ul.list li span.title span.tag {
      color:#fff;
      background:#616161;}
  
    .color019  ul.list li span.title span.tag {
      color:#fff;
      background:#455A64;}

  
    /* メニューリスト関係 */

    section.menu_list {
      width:100%;
      padding:20px 15px;
    }

    .menu_block {
      margin-bottom:15px;
    }

    ul.list {
      width:100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    ul.list li {
    display:block;
    width:calc(50% - 10px);
    margin:0 5px 15px;
    position:relative;
  }

    ul.list.category li {
      margin-bottom:10px;
    }

    ul.list li.size2 {
      width:calc(100% - 10px);
    }

    ul.list li img {
      width:100%;
      margin-bottom:5px;
      position:relative;
      z-index:10;
    }

    ul.list.category li img {
      margin-bottom:0;
    }

    ul.list li span.menu_category {
      display:block;
      width:80%;
      padding:4px 6px;
      background:rgba(255,255,255,0.8);
      line-height:1.2;
      font-size:95%;
      font-weight:500;
      color:#333;
      position:absolute;
      z-index:100;
      left:50%;
      bottom: 15px;
      transform: translate(-50%, -0%);
      -webkit-transform: translate(-50%, 0%);
      -ms-transform: translate(-50%, 0%);
      text-align:center;
    }

    ul.list li span.title {
      display:block;
      width:100%;
      font-size:95%;
      font-weight:400;
      padding-bottom:3px;
    }



    ul.list li span.price {
      display:block;
      width:100%;
      font-size:95%;
      font-weight:700;
      color:#555;
      text-align:right;
    }

    /* メニュー詳細 */
    .menu_single {
      width:100%;
      padding:20px 15px;
    }

    .menu_single h2 {
      font-size:145%;
    }

    .menu_single .menu_img {
      width:100%;
      margin-bottom:5px;
    }

    .menu_single span.price {
      display:block;
      width:100%;
      font-size:115%;
      font-weight:700;
      color:#555;
      text-align:right;
      margin-bottom:20px;
    }

    .menu_single div.text {
      font-size:110%;
      padding:0 10px 15px;
      color:#333;
      line-height:1.8;
    }


    /* クーポン一覧 */
    .coupon_block {
      width:100%;
      padding:20px 15px;
    }

    .coupon_block h2 {
      font-size:125%;
    }

    .coupon_block ul.coupon_list {
      width:100%;
    }

    .coupon_block ul.coupon_list li {
      display:block;
      width:100%;
      margin:0 0px 12px;
      padding-bottom:12px;
      border-bottom:1px solid #ccc;
      position:relative;
    }

    .coupon_block ul.coupon_list li img {
      width:100%;
      margin-bottom:15px;
      position:relative;
      z-index:10;
    }

    .coupon_block ul.coupon_list h3 {
      font-size:135%;
      padding:0 0px 15px;
      font-weight:500;
      color:#333;
      line-height:1.5;
    }
      
    .coupon_block ul.coupon_list div.text {
      font-size:105%;
      padding:0 10px 15px;
      color:#333;
      line-height:1.6;
    }

    .coupon_block ul.coupon_list div.limit {
      font-size:120%;
      padding:0 10px 0px;
      color:#333;
      line-height:1.6;
      text-align:center;
      font-weight:700;
      color:#ff0000;
    }


  }
  